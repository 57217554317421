@use '../util/util';

.logo-wrapper {
  @include util.flex-center-center;

  display: inline-flex;
  flex-direction: column;

  & > * {
    margin: 0.4em;
  }
}

.logo-under-maintenance {
  filter: grayscale(100%);
}

@media only screen and (max-width: 999px) {
  .logo-wrapper > * {
    margin: 0.2em;
  }
}

@use 'util/util';

* {
  @include util.no-padding-no-margin;

  &:focus {
    outline: 0;
  }
}

html, body {
  @include util.no-padding-no-margin;
  @include util.min-full-screen;
  @include util.default-fonts;
}

body {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0.25em;
  }

  &::-webkit-scrollbar-track:vertical {
    background-color: var(--darker-contrast);
  }

  &::-webkit-scrollbar-thumb:vertical {
    background: var(--contrast);
  }

  &::-webkit-scrollbar:horizontal {
    display: none;
  }
}

div * {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

p, a, h1, h2, h3, h4, h5, h6, label, ul, li {
  color: var(--color);
  font-weight: 300;
  word-break: break-word;
  text-decoration: none;
}

hr {
  border: {
    width: 1px;
    style: solid;
    color: var(--lighter-contrast);
  }
}

.dim {
  p, a, h1, h2, h3, h4, h5, h6, label, ul, li {
    @include util.dim;
  }
}

.shine {
  p, a, h1, h2, h3, h4, h5, h6, label, ul, li {
    @include util.shine;
  }
}

a {
  cursor: pointer;

  &:hover {
    color: var(--darker);
  }

  &.link {
    &:hover {
      color: var(--theme-color-darker-1);
    }
  }
}

a.theme-color-link {

  @extend .link;

  color: var(--theme-color-blue);
  font-weight: normal;
}

.font-color {
  color: var(--theme-color);
  font-weight: bold;
}

@media (prefers-color-scheme: dark) {
  body {
    &::-webkit-scrollbar-track:vertical {
      background-color: var(--darker-contrast);
    }

    &::-webkit-scrollbar-thumb:vertical {
      background-color: var(--contrast);
    }
  }
}

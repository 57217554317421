$social-colors: (
  // contact-us
  "google-play": #ff3333,
  "at": #D44638,
  "github": #14171A
);

:root {

  @each $brand,
  $color in $social-colors {
    --#{$brand}: #{$color};
  }
}

@mixin define-social-color($name) {
  .#{$name} {
    --social: var(--#{$name});
  }
}

@each $color,
$_ in $social-colors {
  @include define-social-color(#{$color});
}